.bg {
  z-index:-5;
  top: 0;
  left: 0;
  position:fixed;
  width:100vw;
  height:100vh;
  /*background: linear-gradient(180deg, #FFD0E3 0%, #B9A2D0 100%);*/
  opacity: .3;
  transition: background-color .8s;
}
.bg-of {
  z-index:-5;
  position:fixed;
  top: 0;
  left: 0;
  width:100vw;
  height:100vh;
  background-color: #F4D2A9;
  /*background: linear-gradient(180deg, #F4D2A9 0%, #FFB5B5 100%);*/
  transition: background-color .2s;
}

.app {
  height: 100vh;
  width: 100vw;
}

.App-header {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar {
  width: 50vw;
  height: 50px;
  text-align: center;
  font-family: 'Avenir Next', 'Avenir';
  font-size: 12pt;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.list-container {
  display: flex;
  flex-direction: row;
  gap: 100px;
  padding: 70px;
  align-items: flex-end;
  box-sizing: border-box;
  height: calc(100vh - 100px);
  overflow-x: auto;
}

.cocktail:first-child {
  margin-left: calc(50% - 156px);
}

.cocktail:last-child {
  margin-right: calc(50% - 156px);
}

.cocktail {
  position: relative;
}

.cock-box {
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  background-color: white;
  padding-bottom: 1px;
}

.cock-display-stand {
  height: 100px;
}

.cock-box-info {
  display: flex;
  flex-direction: column;
  font-family: 'Avenir Next', 'Avenir';
  width: 250px;
  height: 40vh;
  padding: 0 20px;
  margin: 10px;
  overflow-y: auto;
  margin-top: -20px;
  /* border: black dashed 1px; */
}

.cock-box-info::-webkit-scrollbar {
  -webkit-appearance: none;
}

.cock-box-info::-webkit-scrollbar:vertical {
  width: 10px;
}

.cock-box-info::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}

.cocktail-item {
  margin-top: 0.5em;
  padding-left: 0.6em;
  text-indent: -0.6em;
}

.ingredients {
  margin: 0;
  margin-left: 0.6em;
}

.ingredient {
  margin-top: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.6em;
}

.ingredient > .bullet-container {
  width: 9px;
  height: 1.5em;
  position: relative;
}

.ingredient > .bullet-container > .outer-bullet {
  width: 9px;
  height: 9px;
  background: black;
  border-radius: 9px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ingredient > .bullet-container > .outer-bullet > .inner-bullet {
  width: 2px;
  height: 2px;
  background: transparent;
  border: solid white 3px;
  border-radius: 10px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ingredient > .label {
  width: calc(100% - 9px - 0.6em);
  overflow-wrap: break-word;
}

.item-key {
  font-weight: 600;
}

.shot-style {
  position:relative;
  z-index: 1;
  border: 1px solid red;
}

.shot-style:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px dashed #bbbbbb;
}

.lowball-style {
  z-index: 1;

  position:relative;
  border: 1px dashed blue;
}

.lowball-style:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border: 2px dashed #bbbbbb;
}

.lowball-style:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px dashed #bbbbbb;
}
.collins-style {

  z-index: 1;

  position:relative;
  border: 1px solid #bbbbbb;
}

.collins-style:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #bbbbbb;
}

.martini-style {

  z-index: 1;

  position:relative;

  border: 1px solid #bbbbbb;
}

.martini-style:before {
  content: " ";
  position: absolute;
  z-index: -1;
  background-color: none;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 1px solid #bbbbbb;
}

.martini-style:after {
  content: " ";
  position: absolute;
  background-color: none;
  z-index: -1;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 1px solid #bbbbbb;
}


.shot-display {
  position: relative;
  bottom: -60px;
  z-index: 2;
}

.collins-display {
  position: relative;
  bottom: -75px;
  z-index: 2;
}

.marg-display {
  position: relative;
  bottom: -80px;
  z-index: 2;
}


.lowball-display {
  position: relative;
  bottom: -80px;
  z-index: 2;
}

.martini-display {
  position: relative;
  bottom: -100px;
  z-index: 2;
}

.marg-display > svg {
  margin: 0 auto;
  margin-left: 20%;
  width: 70%;
}

.lowball-display > svg {
  margin: 0 auto;
  margin-left: 5%;
  width: 90%;
}

.martini-display > svg {
  margin: 0 auto;
  margin-left: 5%;
  width: 90%;
}

.martini-display > svg {
  margin: 0 auto;
  margin-left: 5%;
  width: 90%;
}

.collins-display > svg {
  margin: 0 auto;
  margin-left: 10%;
  width: 80%;
}

.shot-display > svg {
  margin: 0 auto;
  margin-left: 35%;
  width: 30%;
}

.lowball-style > div > .cocktail-name {
  margin-top: 10px;
  font-family: "Garamond";
  font-size: 24pt;
  text-align:center;
}

.shot-style > div > .cocktail-name {
  font-family: "Futura";
  margin-top: 10px;
  text-align:center;
}

.martini-style > div > .cocktail-name {
  font-family: "Sweet Sans Pro";
  margin-top: 10px;
  font-size: 20pt;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align:center;
}

.collins-style > div > .cocktail-name {
  font-family: "SF Pro Rounded";
  margin-top: 10px;
  font-size: 20pt;
  text-align:center;
}

